.note {
    color: black;
}
.noteSection {
    border: 1px solid lightblue;
    display: inline-block;
    float: left;
    padding: 5px;
    width: 100%;
    margin: 5px;
}

.sectionTitle{
    font-weight:bold;
}

.commandBar {
    clear: both;
    padding: 15px;
    border: 1px solid lightblue;
    background-color: lightblue;
    width: 100%;
}
.noteFooter {
    clear: both;
}

.hide{
    visibility: hidden;
}
.hidden {
    display: none;
    visibility: hidden;
}
