.referrals {
    color: black;
}
.referralsRow {
    text-decoration: none;
    cursor: pointer;
}
td, th{
    padding:5px;
}
.referralsSection {
    border: 1px solid lightblue;
    display: inline-block;
    padding: 5px;
    width: 100%;
    margin: 5px;
}

.sectionTitle {
    font-weight: bold;
}
.hidden {
    display: none;
    visibility: hidden;
}
